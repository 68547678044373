@import '../../../styles/variables';
@import '../../../styles/mixins';

.#{$pdp-prefix}-questions {
  @include action-message-question;

  &__questions-list {
    &__title {
      margin-bottom: 16px;
      font-weight: 600;
    }

    &__content {
      background-color: color(color_gray_lighter);
      border-radius: 6px;
    }

    &__content-in-modal {
      padding: 0 36px 0 25px;
    }

    &__answer-container {
      margin-top: $andes-spacing-8;
      margin-left: 12px;
      display: flex;
      align-items: flex-start;

      &__icon {
        margin-right: 8px;
      }

      &__answer {
        width: 100%;
        color: $questions-gray;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
      }
    }

    &__question-item__denounce {
      margin-left: 8px;
    }

    &__question-item__separate {
      margin-left: 8px;
    }

    &__answer-item__separate--denounce-mobile {
      margin-left: 31px;
    }

    &__answer-item__separate {
      margin-right: 8px;
    }

    &__container-answer__isCollpased {
      display: block;
    }

    &__container-answer__isNoCollapsed {
      display: inline;
    }

    &__answer-item__with-tapeables-links > a {
      word-break: break-all;
    }

    &__item-questions--user-questions {
      padding: 16px;
      word-break: break-word;

      &:not(:first-child) {
        padding-top: 0;
      }
    }

    &__item-questions--others-questions {
      padding: 24px 0;
      word-break: break-word;

      &:not(:first-child) {
        padding-top: 0;
      }
    }
  }

  &__no-questions--text {
    font-size: $font-size-14;
    color: color(color_gray_middle);

    &:first-child {
      margin-right: 2px;
    }
  }

  &__no-questions--desktop {
    display: flex;
  }

  &__no-questions--mobile {
    text-align: center;
  }

  &__questions-others-question-modal {
    .ui-pdp-action-modal__link {
      font-size: $font-size-16;
    }

    &__content {
      width: 840px;
      max-height: 90%;
      max-width: unset;
      height: 700px;

      .andes-modal__close-button {
        margin-top: -10px;

        &::before {
          background-color: color(color_gray_middle);
        }

        &::after {
          background-color: color(color_gray_middle);
        }
      }

      .andes-modal__header {
        padding: 45px 64px 20px;

        &-title {
          font-size: $font-size-28;
          margin-left: -14px;
        }

        &--scrolled {
          padding: 22px 64px 19px;

          .andes-modal-title {
            font-size: $font-size-20;
          }
        }
      }

      .andes-modal__content {
        padding: unset;
        height: 100%;
      }
    }
  }

  &__message-location {
    &--simple {
      .#{$pdp-prefix}-media {
        &__figure > svg {
          fill: color(color_gray_dark);
        }

        &__title {
          margin-bottom: 0;
        }
      }

      .#{$pdp-prefix}-media__body {
        margin-bottom: $andes-spacing-24;
      }
    }

    &--container {
      .andes-message__title--neutral {
        font-weight: 100;
      }
    }
  }

  &__questions-ai {
    margin: 36px 0;

    .andes-button__content {
      .ui-pdp-icon {
        width: 20px;
        height: 20px;
        margin-right: $andes-spacing-8;
      }

      .ui-pdp-action-icon {
        &--BLUE {
          fill: #3483fa;
        }

        &--WHITE {
          fill: #fff;
        }
      }
    }
  }
}
