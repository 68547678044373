.#{$pdp-prefix}-actions {
  width: 100%;

  .andes-button {
    width: 100%;

    &.andes-button--small,
    &.andes-button--medium {
      .andes-spinner.andes-button__spinner {
        top: 5px;
      }
    }

    &.andes-button--quiet .andes-spinner .andes-spinner__icon-border {
      border-color: color(color_blue_600);

      &::after {
        background-color: color(color_blue_600);
      }
    }

    .andes-button__content {
      .ui-pdp-icon {
        margin-right: $andes-spacing-8;
      }

      .ui-pdp-action-icon {
        &--BLUE {
          fill: color(color_blue);
        }

        &--WHITE {
          fill: color(color_white);
        }
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $andes-spacing-8;

    .#{$pdp-prefix}-action__card-tooltip {
      margin-top: 18px;
      padding: 0;

      .#{$pdp-prefix}-card-tooltip__card__content {
        align-items: flex-start;
      }
    }
  }

  .andes-button--medium {
    line-height: 32px;
  }

  .andes-progress-indicator-circular {
    position: relative;
    width: 15px;
    height: 15px;
    margin: auto;

    .andes-progress-indicator-circular__svg {
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }

  .#{$pdp-prefix}-action-primary--disabled,
  .#{$pdp-prefix}-action-secondary--disabled {
    cursor: not-allowed;
  }

  // TODO: este fix no es por un problema de andes,
  // sino porque estaba quedando muy separado quantity de los botones,
  // y por algún motivo los margin se estaban sumando en vez de que quede el margin mayor
  // entre el componente superior y el actual
  .#{$pdp-prefix}-action-primary--disabled,
  .#{$pdp-prefix}-action-primary {
    margin-top: 0;
  }

  .#{$pdp-prefix}-action-separator {
    display: block;
    border-bottom: 1px solid $andes-gray-100;
    height: 1px;
    width: 100%;
    margin: $andes-spacing-16 0 $andes-spacing-8;
  }
}

.#{$pdp-prefix}-actions__container--featured {
  .#{$pdp-prefix}-action--primary.andes-button {
    margin-top: 0;
  }

  .andes-button {
    width: 100%;
  }
}
