@import './trade-in';

.ui-pdp-trade-in-modal {
  overflow: hidden;

  .andes-modal__scroll {
    .andes-modal__header {
      display: none;
    }

    .andes-modal__content {
      height: 100%;
      padding: 0;
    }
  }
}

.ui-pdp-trade-in-modal__how-works {
  width: 800px;
  overflow: hidden;

  .ui-pdp-trade-in-modal__content {
    display: flex;
  }

  .andes-modal__actions {
    padding: $andes-spacing-16 $andes-spacing-48 $andes-spacing-48;
  }

  .andes-modal__actions.padding {
    .ui-pdp-trade-in-modal__actions {
      padding-bottom: $andes-spacing-16;
    }
  }

  .andes-modal__actions.andes-modal__actions--border {
    padding-bottom: $andes-spacing-16;
  }
}
