// sass-lint:disable bem-depth

// Styles from lib

@import '@vpp-frontend-components/styled-label/index';

// VPP Common
@import '../../../../styles/common-andes-fixes';
@import '../../../../styles/common-navigation-fixes';

// CxWidget daisy
@import '~help-daisy/styles/CxWidget';

// VIP Internal imports
@import '../../../../components/iframe/styles/iframe';
@import '../../../../components/payment/payment';
@import '../../../../components/grid/styles/grid';
@import '../../../../components/media/media';
@import '../../../../styles/icons';
@import '../../../../components/thumbnail/thumbnail';
@import '../../../../components/title/styles/title';
@import '../../../../components/placeholder/placeholder';
@import '../../../../components/returns/styles/returns';
@import '../../../../components/benefits/styles/benefits';
@import '../../../../components/additional-info/styles/additional-info';
@import '../../../../components/buy-protected/styles/buy-protected';
@import '../../../../components/specs/specs';
@import '../../../../components/reviews/styles/reviews';
@import '../../../../components/qadb/styles/qadb';
@import '../../../../components/flash-message/flash-message';
@import '../../../../components/rating-levels/styles/rating-levels';
@import '../../../../components/apparel-size-specs/styles/apparel-size-specs';
@import '../../../vip/views/questions/questions';
@import '../../../../components/data-display/progress-bar/styles/progress-bar';
@import '../../../../components/buybox/actions/styles/action';
@import '../../../../components/buybox/counter-button/component/styles/counter-button';
@import '../../../../components/buybox/cart-actions/styles/cart-actions-container';
@import '../../../../components/loyalty/styles/loyalty';
@import '../../../../components/message/styles';
@import '../../../../components/clip-video/styles';
@import '../../../../components/clip-multimedia/styles';
@import '../../../../components/dots-navigation/styles/dots-navigation';
@import '../../../../components/exit-popup/styles/exit-popup';
@import '../../../../components/exit-popup-web-mobile/styles/exit-popup-web-mobile';
@import '../../../../components/card-with-thumbnails/styles/card-with-thumbnails';
@import '../../../../components/grouped-share-bookmark/grouped-share-bookmark';
@import '../../../../components/price-per-quantity-shortcuts/styles/price-per-quantity-shortcuts';
@import '../../../../components/coachmarks/styles/coachmarks';

.#{$vip-core-prefix} {
  font-size: $font-size;
  margin: 0 auto;
}

.ui-pdp-container__row {
  flex: 0 1 auto;
}

.ui-pdp-benefits {
  padding: 0 16px;
}

.ui-pdp-qadb {
  border-top: 1px solid $andes-gray-100;
  padding-top: 40px;
}

.ui-pdp-qadb__questions-list__answer-item__answered-by {
  display: inline;
}

.ui-review-capability-vpp {
  width: 100%;

  .andes-button[type='submit'] {
    width: 100%;
    margin: $andes-spacing-20 0;
  }

  @media (width <= 480px) {
    margin-bottom: 80px;
  }
}

.onboarding-cp-button {
  height: auto;
}

.shops__pills {
  margin: $andes-spacing-16;
  position: absolute;
  z-index: 1;
}

.ui-pdp .ui-pdp-container__row--grouped-share-bookmark {
  margin: 0 0 24px 8px;
}

.ui-pdp .ui-pdp-container__row--gift-registry {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ui-vip-core {
  .ui-pdp-container__row--returns-notification-bottom .ui-pdp-message {
    margin: $andes-spacing-16 0 0;
  }

  .ui-pdp-container__row--returns-notification-up .ui-pdp-message {
    margin: $andes-spacing-16 0 -5px;
  }
}

.snackbar--remove-bookmark,
.snackbar--add-bookmark {
  margin-bottom: 32px !important;
}
